<template>
  <div id="app">
    <el-row class="content">
        <!-- 顶部导航 -->
        <el-col :span="24" class="navbox">
            <div class="topnav">
                <el-col :span="6" class="nav_left"><img src="../src/assets/image/logo_nav.png"></el-col>
                <el-col :span="18" class="nav_right">
                    <div class="item" :class="activeNav == index? 'active':''"
                     v-for="(item,index) in navList" :key="index" @click="toNext(item,index)" 
                     >{{item.title}}</div>
                </el-col>
            </div>
        </el-col>
        <!-- flex 版权自适应居底 -->
        <div class="flex_clubox">
            <!-- 轮播图 -->
            <div class="swiperbox" v-show="activeNav == 0" v-if="webdata !== null">
                <el-carousel height="400px" :interval="5000">
                    <el-carousel-item v-for="(item,index) in webdata.images_arr" :key="index">
                        <img :src="item">
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div v-show="activeNav !== 0" style="position: relative;">
                <!-- 其它页面 图片 -->
                <img :src="nowActiveData.src" style="height: 300px;width: 100%;">
                <!-- 其它页面 标题 -->
                <div class="othertitle">
                    <div class="title">{{nowActiveData.title}}</div>
                    <div class="lien"></div>
                    <div class="Ftitle">{{nowActiveData.F_title}}</div>
                </div>
            </div>
            <!-- 内容区域 -->
            <div class="mainbox" :style="activeNav == 0 ? '':'margin-top:-40px;'">
                <router-view ref="childe"></router-view>
            </div>
        </div>
        <!-- 底部版权 -->
        <el-col :span="24" class="bottombox">
            <div class="bottomnav"><span v-for="(item,index) in bottomnav" :key="index" @click="toNext(item)">{{item.title}}</span></div>
            <div style="height: 50%;display: flex;align-items: center;justify-content: center;"><span>版权所有</span> <span>@ 临沂上东电子商务有限公司</span> <span @click="toNext('https://beian.miit.gov.cn')" class="ICP_text">鲁ICP·备2021003146号-2</span><span @click="toNext('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37132102371524')" class="ICP_text" style="display: flex;align-items: center;"><img src="../src/assets/image/bei_icon.png" style="margin-right:5px;"> 鲁公网安备 37132102371524号</span></div>
        </el-col>
    </el-row>
  </div>
</template>

<script>
import IndexPage from './components/IndexPage.vue'

export default {
  name: 'app',
  components: {
    IndexPage
  },
  data(){
      return{
          // 顶部导航列表
          navList:[{
              title:'首页',
              to:'/',
              src:''
          },{
              title:'车易通简介',
              F_title:'Company profile',
              to:'/detailBusi',
              src:require('../src/assets/image/topimg_02.png')
          },{
              title:'新闻资讯',
              F_title:'News Center',
              to:'/news',
              src:require('../src/assets/image/topimg_03.png')
          },{
              title:'业务办理',
              to:''
          }],
          nowActiveData:{
              title:'',
              F_title:'',
              src:''
          },//当前选中的数据
          bottomnav:[{
              title:'隐私保护',
              F_title:'Privacy protection',//副标题
              to:'/userpriv',
              src:require('../src/assets/image/topimg_01.png')
          },{
              title:'使用协议',
              F_title:'Use agreement',
              to:'/useagr',
              src:require('../src/assets/image/topimg_01.png')
          },{
              title:'联系我们',
              F_title:'contact us',
              to:'/callme',  
              src:require('../src/assets/image/topimg_05.png')
          },{
              title:'公司简介',
              F_title:'Company profile',
              to:'/detailFirm',
              src:require('../src/assets/image/topimg_01.png')
          },{
              title:'投诉与建议',
              F_title:'Feedback',
              to:'/feed',
              src:require('../src/assets/image/topimg_04.png')
          }],//底部导航
          activeNav:0,//当前选中
          webdata:null,//网站设置
          
      }
  },
  methods:{
        toNext(item,index){
            console.log(item.hasOwnProperty('to'))
            if(!item.hasOwnProperty('to')){
                window.open(item)
                return
            }
            if(item.to == this.$route.path) return
            if(item.title == '业务办理'){
                window.open(this.webdata.cx_url)
                return
            }
            this.nowActiveData = item
            if(index!==undefined){
                this.activeNav = index
            }else{
                this.activeNav = -1
            }
            if(item.title !== '首页'){
                document.title = item.title
            }else{
                document.title = '上东车易通'
            }
            this.$router.push({path:item.to})
            this.getWebdata()
        },
        // 获取网页配置
        async getWebdata(){
            await this.axios.post("car.website_set/list")
            .then(({data}) => {
                if(data.code == 200){
                    this.$refs.childe.webdata = data.data
                    this.webdata = data.data
                } else{
                    this.$message.error(data.msg);
                }
            })
            .catch((error) => {
                console.log(error)
            })
        }
  },
  created() {
      if(this.$route.path !== '/'){
          this.$router.push('/')
      }
      this.getWebdata()
  }
}
</script>

<style lang="scss">
    body{
        padding: 0;
        margin: 0;
        background-color: #F1F1F1;
        display: flex;
        flex-direction: column;
    }
    .content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 65px;
        min-height: 100vh;
    }
    // flex 上部盒子
    .flex_clubox{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
        margin-bottom:33px ;
    }
    // 顶部导航
    .navbox{
        position: fixed;
        top: 0;
        display: flex;
        justify-content: center;
        background-color: #009CF8;
        height: 65px;
        z-index: 11;
        
        .topnav {
            width: 1200px;
            display: flex;
            align-items: center;
            color: #fff;
            
            .nav_left{
                display: flex;
                align-items: center;
                img{
                    width: 153px;
                    height: 44px;
                }
            }
            
            .nav_right {
                display: flex;
                justify-content: flex-end;
                
                .item{
                    padding: 0 10px;
                    font-size: 14px;
                    color: #7ECFFF;
                    position: relative;
                    &:hover{
                        cursor: pointer;
                        color: #cbeff8;
                    }
                }
            
                .active{
                    color: #FFFFFF;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 33px;
                        height: 3px;
                        background-color: #fff;
                        bottom: -16px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
            
        }
    }
    // 轮播图
    .swiperbox{
        height: 400px;
        margin-bottom: 16px;
        width: 1200px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    // 其它页面 标题
    .othertitle{
        text-transform: uppercase;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: #fff;
        text-align: center;
        .title{
            font-weight: 500;
            font-size: 40px;
            margin-bottom: 17px;
        }
        .Ftitle{
            font-size: 20px;
            margin-top: 12px;
        }
        .lien{
            width: 28px;
            height: 4px;
            background-color: #fff;
            margin: auto;
        }
    }
    // 内容显示区域
    .mainbox{
        position: relative;
        width: 1200px;
        .comp_content{
            min-height: 400px;
        }
    }
    // 底部版权
    .bottombox{
        background: #464646;
        color: #fff;
        height: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 12px;
        span{
            margin-right: 10px;
        }
    }
    // 底部导航
    .bottomnav{
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #aaaaaa;
        span{
            cursor: pointer;
            &:hover{
                color: #cbeff8;
            }
        }
    }
    // 修改轮播图原样式
    .el-carousel__button{
        width: 20px !important;
        height: 5px !important;
        border-radius: 3px !important;
    }
    .is-active .el-carousel__button{
        background-color: #009CF8 !important;
    }
    // 备案号文字样式
    .ICP_text{
        cursor: pointer;
        &:hover{
            color: #cbeff8;
        }
    }
</style>
