<template>
    <div style="margin-top: 40px;">
        <div class="navtext"><span @click="tonews()" style="cursor: pointer;">新闻中心</span>&nbsp;>>&nbsp;<span>新闻详情</span></div>
        <div class="comp_content" v-if="newsDetail !== null">
            <div class="title_box">
                <div class="title">{{newsDetail.title}}</div>
                <div class="time">{{newsDetail.createtime | getDate}}</div>
            </div>
            <div v-html="newsDetail.details"></div>
        </div>
    </div>
</template>

<script>
    import qs from 'qs'
    
export default{
    data(){
        return{
            newsDetail:null,//新闻
        }
    },
    methods:{
        tonews(){
            this.$router.push(`/news`)
        },
        async getNews(id){
            let args = {
                id
            }
            await this.axios.post("car.Websitenew/detail",qs.stringify(args))
            .then(({data}) => {
                if(data.code == 200){
                    this.newsDetail = data.data
                } else{
                    this.$message.error(data.msg);
                }
            })
            .catch((error) => {
                console.log(error)
            })
        }
    },
    created() {
        this.getNews(this.$route.query.id)
    },
    filters:{
        // 处理时间戳
        getDate(timer){
            let date = new Date(timer*1000)
            //获取年份
            var Y = date.getFullYear() + '年';
            //获取月份，如果不超过10就在前面拼接上0
            var M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '月' : (date.getMonth() + 1) + '月';
            //获取天数
            var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + '日';
            return (Y + M + D);
        }
    }
}
</script>

<style scoped lang="scss">
    .comp_content{
        background-color: #fff;
        padding: 30px;
        padding-top: 0;
        box-sizing: border-box;
    }
    .navtext{
        height: 65px;
        display: flex;
        align-items: center;
        font-size: 15px;
        span:last-child {
            color: #009CF8;
        }
    }
    .title_box{
        height: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-bottom: 1px solid #F5F5F5;
        margin-bottom: 20px;
        .title{
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 14px;
        }
        .time{
            font-size: 12px;
        }
    }
</style>