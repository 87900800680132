<template>
    <div class="comp_contnet">
        <div class="mainbox" v-if="webdata !== null">
            <el-card class="item" shadow="hover">
                <div class="title">关于我们</div>
                <div class="text" v-html="webdata.about_us"></div>
            </el-card>
            <el-card class="item" shadow="hover">
                <div class="title">客户端小程序</div><div class="wx_box" v-html="webdata.applet">
                </div>
            </el-card>
            <el-card class="item" shadow="hover">
                <div class="title">服务市场</div>
                <div class="phone_item" v-html="webdata.market"></div>
            </el-card>
        </div>
        <div style="font-size: 16px;text-align: center;">上东车易通 · 二手车交易好帮手</div>
    </div>
</template>

<script>


export default {
  name: 'IndexPage',
  components: {
    
  },
  data(){
      return{
          webdata:null
      }
  }
}
</script>

<style scoped lang="scss">
    .comp_contnet{
        width: 100%;
    }
    .wx_box{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
            margin-top: 6px;
            width: 180px;
            height: 180px;
        }
        .wx_txt1{
            font-size: 14px;
        }
        .wx_txt2{
            font-size: 12px;
            color: #999999;
            margin-top: 5px;
        }
    }
    .mainbox{
        display: flex;
        justify-content: space-between;
        .item{
            min-height: 370px;
            margin-bottom: 35px;
            border-radius: 10px;
            box-sizing: border-box;
            width: 32%;
            .title{
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 10px;
                border-bottom: 1px solid #EBEEF5;
                box-sizing: border-box;
                padding-bottom: 14px;
            }
            // .text{
            //     word-wrap: break-word;
            //     word-break: normal;
            // }
        }
    }
</style>