<template>
    <div class="comp_content">
        <!-- 新闻资讯分类 -->
        <div class="ifyList">
            <div class="item" :class="activeIndex == 0? 'active':''" @click="activeItem(0)">公司新闻</div>
            <div class="item" :class="activeIndex == 1? 'active':''" @click="activeItem(1)">行业资讯</div>
        </div>
        <!-- 新闻资讯列表 -->
        <div class="listbox">
            <div class="item" v-for="item in newsList" :key="item.id" @click="nextpage(item.id)">
                <img :src="item.cover" class="img">
                <div class="textsbox">
                    <div class="title multi-ellipsis">{{item.title}}</div>
                    <div class="time">{{item.createtime | getDate}}</div>
                </div>
            </div>
        </div>
        <!-- 分页 -->
        <el-pagination
          background hide-on-single-page
          layout="prev, pager, next"
          :total="totalpage"
          @current-change="pagechange"
          style="margin-top: 40px;"
          >
        </el-pagination>
    </div>
</template>

<script>
    import qs from 'qs'
    
export default{
    data(){
        return{
            newsList:[],
            activeIndex:0,//选中的下标
            totalpage:0,//总页数
            page:1,//页码
        }
    },
    methods:{
        // 选择分类
        activeItem(index){
            this.activeIndex = index
            this.getNewList()
        },
        // 获取新闻列表 
        async getNewList(){
            let args = {
                type:this.activeIndex,
                page:this.page
            }
            await this.axios.post("car.Websitenew/list",qs.stringify(args))
            .then(({data}) => {
                if(data.code == 200){
                    this.newsList = data.data.data
                    this.totalpage = data.data.last_page
                } else{
                    this.$message.error(data.msg);
                }
            })
            .catch((error) => {
                console.log(error)
            })
        },
        // 跳转详情
        nextpage(id){
            this.$router.push(`/news/detail?id=${id}`)
        },
        // 下一页
        pagechange(page){
            this.page = page
            this.getNewList()
        }
    },
    created() {
        this.getNewList()
    },
    filters:{
        // 处理时间戳
        getDate(timer){
            let date = new Date(timer*1000)
            //获取年份
            var Y = date.getFullYear() + '年';
            //获取月份，如果不超过10就在前面拼接上0
            var M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '月' : (date.getMonth() + 1) + '月';
            //获取天数
            var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + '日';
            return (Y + M + D);
        }
    }
}
</script>

<style scoped lang="scss">
    .comp_content{
        background-color: #fff;
        padding: 30px;
        padding-top: 0;
        box-sizing: border-box;
        text-align: center;
        display: flex;
        flex-direction: column;
    }
    .ifyList{
        display: flex;
        justify-content: center;
        width: 100%;
        border-bottom: 1px solid #F4F2F2;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
        .item{
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            width: 49.9%;
        }
        .active{
            color: #009CF8;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                bottom: 0;
                width: 33px;
                height: 3px;
                background-color: #009CF8;
            }
        }
    }
    .listbox{
        .item{
            display: flex;
            margin-top: 20px;
            .img{
                width: 175px;
                height: 110px;
            }
            .textsbox{
                cursor: pointer;
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                text-align: left;
                flex: 1;
                .title{
                    flex: 1;
                    font-size: 16px;
                    font-weight: bold;
                }
                .time{
                    font-size: 14px;
                    color: #5B5B5B;
                }
            }
        }
    }
    // 文字多行省略
    .multi-ellipsis {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
</style>