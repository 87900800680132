<template>
    <div class="comp_content" v-html="webdata.business_profile" v-if="webdata !== null"></div>
    <div class="comp_content" v-else></div>
</template>

<script>
export default {
    data(){
        return{
            webdata:null
        }
    }
}
</script>

<style scoped lang="scss">
    .comp_content{
        background-color: #fff;
        padding: 30px;
        padding-top: 10px;
        box-sizing: border-box;
        text-align: center;
    }
</style>