import { render, staticRenderFns } from "./detailPageBusi.vue?vue&type=template&id=03a1c0aa&scoped=true&"
import script from "./detailPageBusi.vue?vue&type=script&lang=js&"
export * from "./detailPageBusi.vue?vue&type=script&lang=js&"
import style0 from "./detailPageBusi.vue?vue&type=style&index=0&id=03a1c0aa&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03a1c0aa",
  null
  
)

export default component.exports