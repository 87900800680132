import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexPage from '../components/IndexPage.vue'
import detailPageFirm from '../components/detailPageFirm.vue'
import detailPageBusi from '../components/detailPageBusi.vue'
import newsCenter from '../components/newsCenter.vue'
import newsDetail from '../components/detailNews.vue'
import feedback from '../components/feedback.vue'
import callMe from '../components/callMe.vue'
import useAgreement from '../components/useAgreement.vue'
import userPrivacy from '../components/userPrivacy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: IndexPage
  },
  {
    path: '/detailFirm',
    name: 'detailFirm',
    component: detailPageFirm
  },
  {
    path: '/detailBusi',
    name: 'detailBusi',
    component: detailPageBusi
  },
  {
    path: '/news',
    name: 'news',
    component: newsCenter
  },
  {
    path: '/feed',
    name: 'feed',
    component: feedback
  },
  {
    path: '/callme',
    name: 'callme',
    component: callMe
  },
  {
      path: '/news/detail',
      name: 'detail',
      component: newsDetail,
  },
  {
      path: '/useagr',
      name: 'useagr',
      component: useAgreement,
  },
  {
      path: '/userpriv',
      name: 'userpriv',
      component: userPrivacy,
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes,
  mode: 'history',
})

export default router
