<template>
    <div class="comp_content">
        <div class="back_title">意见反馈</div>
        <el-form ref="form" :model="feeddata" :rules="rules" class="labelbox" label-width="80px">
            <el-form-item label="联系人" prop="name">
                <el-input v-model="feeddata.name" placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
                <el-input v-model="feeddata.phone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="意见反馈" prop="feedback">
                <el-input type="textarea" v-model="feeddata.feedback" placeholder="请输入反馈内容"></el-input>
            </el-form-item>
            <el-form-item style="margin-top: 50px;">
                <el-button type="primary" @click="submitForm()">提交信息</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import qs from 'qs'
export default{
    data(){
        var isphone = (rule, value, callback) => {
            let res = /^[1][3,4,5,6.7,8,9][0-9]{9}$/
            if(value !== ''&&!res.test(value)){
                callback(new Error('输入的手机号不合法！'))
            }else{
                callback()
            }
        }
        return{
            feeddata:{
                name:'',
                phone:'',
                feedback:''
            },
            rules:{
                name:[{ required: true, message: '输入的内容不能为空！', trigger: 'blur' }],
                phone:[{ required: true, message: '输入的内容不能为空！', trigger: 'blur' },{ validator: isphone, trigger: 'blur'}],
                feedback:[{ required: true, message: '输入的内容不能为空！', trigger: 'blur' }]
            }
        }
    },
    methods:{
        submitForm() {
            var info = {
                name:this.feeddata.name,
                phone:this.feeddata.phone,
                content:this.feeddata.feedback
            }
            this.$refs['form'].validate((valid) => {
                console.log(info)
                if (valid) {
                    this.axios.post("car.website_feedback/add",qs.stringify(info))
                    .then(({data}) => {
                        if(data.code == 200){
                            console.log(data)
                            this.$message({
                                message: '提交成功！',
                                type: 'success'
                            });
                        } else{
                            this.$message.error(data.msg);
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                }
            });
        },
    }
}
</script>

<style lang="scss">
    .comp_content{
        background-color: #fff;
        padding: 30px;
        box-sizing: border-box;
        text-align: center;
    }
    .labelbox{
        width: 40%;
        margin: auto;
    }
    .el-textarea{
        height: 140px;
    }
    .el-textarea__inner{
        height: 100%;
    }
    .back_title{
        text-align: center;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 25px;
    }
</style>